import revive_payload_client_3S4iYDRKZn from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_sass@1.71.1_typescript@5.4.2_vite@4.5.2_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_afAwbSvjF5 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_sass@1.71.1_typescript@5.4.2_vite@4.5.2_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_YuwA9wny2e from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_sass@1.71.1_typescript@5.4.2_vite@4.5.2_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Ja0Z1Jt309 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_sass@1.71.1_typescript@5.4.2_vite@4.5.2_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_3xs9lbvgFP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_sass@1.71.1_typescript@5.4.2_vite@4.5.2_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_qjEs6Szyge from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.12.1_typescript@5.4.2_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_72K3dR4enJ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_sass@1.71.1_typescript@5.4.2_vite@4.5.2_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_uqgrPZhj16 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_rollup@4.12.1_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import primevue_plugin_egKpok8Auk from "/opt/buildhome/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_hiZlPaez3w from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-primevue@0.3.1_rollup@4.12.1_vue@3.4.21/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import chunk_reload_client_LGQw7vldRy from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_sass@1.71.1_typescript@5.4.2_vite@4.5.2_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import firebase_C8Q6prrMh8 from "/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_YnQoZZt25s/plugins/firebase.ts";
import middleware_global_n2kD5OXGN2 from "/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_YnQoZZt25s/plugins/middleware.global.ts";
import primevue_global_tQV8b2LKLK from "/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_YnQoZZt25s/plugins/primevue.global.ts";
export default [
  revive_payload_client_3S4iYDRKZn,
  unhead_afAwbSvjF5,
  router_YuwA9wny2e,
  payload_client_Ja0Z1Jt309,
  check_outdated_build_client_3xs9lbvgFP,
  plugin_vue3_qjEs6Szyge,
  components_plugin_KR1HBZs4kY,
  prefetch_client_72K3dR4enJ,
  i18n_uqgrPZhj16,
  primevue_plugin_egKpok8Auk,
  plugin_client_hiZlPaez3w,
  chunk_reload_client_LGQw7vldRy,
  firebase_C8Q6prrMh8,
  middleware_global_n2kD5OXGN2,
  primevue_global_tQV8b2LKLK
]