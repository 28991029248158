// import { useFeatureFlag } from 'feature-flag';

type NavigationMenuItem = {
    label: string;
    to?: string;
    icon?: string;
    items?: NavigationMenuItem[];
    separator?: boolean;
};

export async function useNavigationMenu (reset = false) {
    const state = useGlobalState();

    if (reset) {
        state.navigationMenu.value = [];
        return;
    }

    state.navigationLoading.value = true;

    const contexts = await getAccountContexts();

    state.navigationMenu.value = contexts.map((context) => {
        return {
            label: context.name,
            to: `/contexts/${context.id}/bookings`,
        };
    });

    state.navigationLoading.value = false;
}
