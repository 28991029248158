import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_sass@1.71.1_typescript@5.4.2_vite@4.5.2_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45check_45global from "/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_YnQoZZt25s/middleware/auth-check.global.ts";
import trailing_45slash_45global from "/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_YnQoZZt25s/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_sass@1.71.1_typescript@5.4.2_vite@4.5.2_vue-tsc@1.8.27/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45check_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}