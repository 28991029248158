import type { IAccountContextSingleResponse, IAccountContextResponse, IAccountContextSingle, IAccountContext, IAccountContextRequest, TContextType, TContextPaymentType } from '~/types/account-contexts';

/**
 * Retrieves a list of Account Contexts.
 *
 * @returns {Promise<IAccountContext[]>} - A promise that resolves to an array of contexts
 */
export async function getAccountContexts (): Promise<IAccountContext[]> {
    const response = await useAPI<{ data: IAccountContextResponse[] }>('/v1/manager/account-context', {
        method: 'GET',
    });

    return (response.data || []).map((context: IAccountContextResponse) => formatAccountContext(context));
}

/**
 * Retrieves a single context by its ID.
 *
 * @param {number} contextId - The ID of the context to retrieve.
 * @returns {Promise<IAccountContextSingle>} - A promise that resolves to the details of a single context.
 */
export async function getAccountContext (contextId: number): Promise<IAccountContextSingle> {
    const response = await useAPI<IAccountContextSingleResponse>(`/v1/manager/account-context/${contextId}`, {
        method: 'GET',
    });

    return formatAccountContext(response.data);
}

/**
 * Formats a single Context response from the server into a front end object
 * @param {IAccountContextResponse} context A response object received from the API
 * @returns {IAccountContext}
 */
export function formatAccountContext (context: IAccountContextResponse, accountId?: number): IAccountContext {
    return {
        ...context,
        combinedId: `${context.account?.id}-${context.id}`,
        combinedName: `${context.account?.name} - ${context.name}`,
        type: context.type as TContextType,
        paymentType: context.paymentType as TContextPaymentType,
        accountId: accountId || context.account?.id || -1,
    };
}
