import { default as _4047Xi4OILrTdMeta } from "/opt/buildhome/repo/pages/404.vue?macro=true";
import { default as info6qAMOWB4PQMeta } from "/opt/buildhome/repo/pages/contexts/[contextId]/index/bookings/[bookingId]/index/info.vue?macro=true";
import { default as indexkUrCXrCS3TMeta } from "/opt/buildhome/repo/pages/contexts/[contextId]/index/bookings/[bookingId]/index.vue?macro=true";
import { default as indexzTBRhrixYFMeta } from "/opt/buildhome/repo/pages/contexts/[contextId]/index/bookings/index.vue?macro=true";
import { default as indexc699YS4snzMeta } from "/opt/buildhome/repo/pages/contexts/[contextId]/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as test_45base_45layerlB9DukPUJNMeta } from "/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_YnQoZZt25s/pages/test-base-layer.vue?macro=true";
export default [
  {
    name: _4047Xi4OILrTdMeta?.name ?? "404",
    path: _4047Xi4OILrTdMeta?.path ?? "/404",
    meta: _4047Xi4OILrTdMeta || {},
    alias: _4047Xi4OILrTdMeta?.alias || [],
    redirect: _4047Xi4OILrTdMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/404.vue").then(m => m.default || m)
  },
  {
    name: indexc699YS4snzMeta?.name ?? "contexts-contextId",
    path: indexc699YS4snzMeta?.path ?? "/contexts/:contextId()",
    meta: indexc699YS4snzMeta || {},
    alias: indexc699YS4snzMeta?.alias || [],
    redirect: indexc699YS4snzMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contexts/[contextId]/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexkUrCXrCS3TMeta?.name ?? "contexts-contextId-index-bookings-bookingId",
    path: indexkUrCXrCS3TMeta?.path ?? "bookings/:bookingId()",
    meta: indexkUrCXrCS3TMeta || {},
    alias: indexkUrCXrCS3TMeta?.alias || [],
    redirect: indexkUrCXrCS3TMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contexts/[contextId]/index/bookings/[bookingId]/index.vue").then(m => m.default || m),
    children: [
  {
    name: info6qAMOWB4PQMeta?.name ?? "contexts-contextId-index-bookings-bookingId-index-info",
    path: info6qAMOWB4PQMeta?.path ?? "info",
    meta: info6qAMOWB4PQMeta || {},
    alias: info6qAMOWB4PQMeta?.alias || [],
    redirect: info6qAMOWB4PQMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contexts/[contextId]/index/bookings/[bookingId]/index/info.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexzTBRhrixYFMeta?.name ?? "contexts-contextId-index-bookings",
    path: indexzTBRhrixYFMeta?.path ?? "bookings",
    meta: indexzTBRhrixYFMeta || {},
    alias: indexzTBRhrixYFMeta?.alias || [],
    redirect: indexzTBRhrixYFMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contexts/[contextId]/index/bookings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login4dfr6kRqBoMeta?.name ?? "login",
    path: login4dfr6kRqBoMeta?.path ?? "/login",
    meta: login4dfr6kRqBoMeta || {},
    alias: login4dfr6kRqBoMeta?.alias || [],
    redirect: login4dfr6kRqBoMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: test_45base_45layerlB9DukPUJNMeta?.name ?? "test-base-layer",
    path: test_45base_45layerlB9DukPUJNMeta?.path ?? "/test-base-layer",
    meta: test_45base_45layerlB9DukPUJNMeta || {},
    alias: test_45base_45layerlB9DukPUJNMeta?.alias || [],
    redirect: test_45base_45layerlB9DukPUJNMeta?.redirect,
    component: () => import("/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_YnQoZZt25s/pages/test-base-layer.vue").then(m => m.default || m)
  }
]