<script setup>
const { disableMenuExpand, expandMenu } = useLayout();
const user = useUser();
const state = useGlobalState();
const { showLoadMask, hideLoadMask } = useLayout();
const route = useRoute();

watch(() => user.value, async () => {
    if (user.value) {
        // showLoadMask();
        await useNavigationMenu();

        if (state.navigationMenu.value.length > 0 && route.fullPath === '/') {
            navigateTo(state.navigationMenu.value[0].to);
        } else if (state.navigationMenu.value.length === 0) {
            navigateTo('/');
        }

        // hideLoadMask();
    } else {
        await useNavigationMenu(true);
    }
}, { immediate: true });
expandMenu();
disableMenuExpand();

useHead({
    title: 'Go Expond Manager'
});
</script>

<template>
    <NuxtLayout>
        <Head>
            <Link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <Link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <Link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <Link rel="manifest" href="/site.webmanifest" />
            <Link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
            <Meta name="msapplication-TileColor" content="#2b5797" />
            <Meta name="theme-color" content="#ffffff" />
        </Head>
        <NuxtPage />
        <Toast />
        <ConfirmDialog />
        <UxLoadMask />
    </NuxtLayout>
</template>

<style lang='scss'>

</style>
